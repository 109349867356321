<template>
  <div class="d-flex flex-column flex-root tajawal-font">
    <div
        class="login login-1 d-flex flex-column flex-md-row flex-column-fluid bg-white"
        :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
        id="kt_login"
    >
      <!--begin::Content-->
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <a href="https://sajlha.com" class="mb-10 d-flex justify-content-end">
                  <img :src="main_logo" class="max-h-55px d-md-none d-block" alt="sajlha"/>
                </a>
                <h3 class="font-weight-800 color-f5f3ee f-s-3rem">{{ $t('login_page.Sign_In') }}</h3>
                <p class="color-f5f3ee f-s-22px">{{ $t('login_page.unlimited_possibilities_to_manage_your_business') }}</p>
              </div>
              <div class="form-group">
                <!--                <label class="font-size-h6 font-weight-bolder text-dark">{{$t('login_page.Email')}}</label>-->
                <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                >
                  <input
                      class="form-control form-control-solid h-auto py-4 px-4 rounded-lg form-control-login-custom"
                      :class="validation && validation.email ? 'is-invalid' : ''"
                      type="text"
                      name="email"
                      ref="email"
                      v-model="form.email"
                      :placeholder="$t('login_page.Email')"
                  />
                  <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.email[0] }}
                  </span>
                  <span v-if="error_msg" class="d-block fv-plugins-message-container invalid-feedback">
                      {{ error_msg }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <!--                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">{{$t('login_page.Password')}}</label>-->
                  <!--                  <a-->
                  <!--                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"-->
                  <!--                    id="kt_login_forgot"-->
                  <!--                    @click="showForm('forgot')"-->
                  <!--                    >Forgot Password ?</a-->
                  <!--                  >-->
                </div>
                <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                >
                  <input
                      class="form-control form-control-solid h-auto py-4 px-4 rounded-lg form-control-login-custom"
                      :class="validation && validation.password ? 'is-invalid' : ''"
                      type="password"
                      name="password"
                      ref="password"
                      v-model="form.password"
                      autocomplete="off"
                      :placeholder="$t('login_page.Password')"
                  />
                  <span v-if="validation && validation.password" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.password[0] }}
                  </span>
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <div class="d-block text-center mt-5">
                  <button ref="kt_login_signin_submit" class="btn btn-warning btn-warning-login-custom font-weight-bolder font-size-h6">
                    {{ $t('login_page.Sign_In') }}
                  </button>
                </div>
                <div class="d-block text-center mt-5">
                  <a :href="getLinkResetPassword()" class="btn btn-link color-f5f3ee">
                    {{ $t('login_page.Forgotten_Password') }}
                  </a>
                </div>

              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t('login_page.Sign_Up') }}

                </h3>
                <p class="text-muted font-weight-bold font-size-h4">

                  {{ $t('login_page.Enter_your_details_to_create_your_account') }}
                </p>
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-4 rounded-lg font-size-h6"
                    type="text"
                    placeholder="Fullname"
                    name="fullname"
                    ref="fullname"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-4 rounded-lg font-size-h6"
                    type="email"
                    placeholder="Email"
                    name="email"
                    ref="remail"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-4 rounded-lg font-size-h6"
                    type="password"
                    placeholder="Password"
                    name="password"
                    ref="rpassword"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-4 rounded-lg font-size-h6"
                    type="password"
                    placeholder="Confirm password"
                    name="cpassword"
                    ref="cpassword"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree"/>
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                    ref="kt_login_signup_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                    style="width:150px;"
                >
                  {{ $t('login_page.Submit') }}
                </button>
                <button
                    type="button"
                    id="kt_login_signup_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                >
                  {{ $t('login_page.Cancel') }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_forgot_form"
                ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t('login_page.Forgotten_Password') }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">

                  {{ $t('login_page.Enter_your_email_to_reset_your_password') }}
                </p>
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-4 rounded-lg font-size-h6"
                    type="email"
                    placeholder="Email"
                    name="email"
                    autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                    type="button"
                    id="kt_login_forgot_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  {{ $t('login_page.Submit') }}

                </button>
                <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                >
                  {{ $t('login_page.Cancel') }}

                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <!--        <div-->
        <!--            class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"-->
        <!--        >-->
        <!--          <a href="https://sajlha.com/terms-conditions" class="text-primary font-weight-bolder font-size-h5"-->
        <!--          >{{$t('login_page.Terms')}}</a-->
        <!--          >-->
        <!--          <a href="https://sajlha.com/plans" class="text-primary ml-10 font-weight-bolder font-size-h5"-->
        <!--          >{{$t('login_page.Plans')}}</a-->
        <!--          >-->
        <!--          <a href="https://sajlha.com/contact-us" class="text-primary ml-10 font-weight-bolder font-size-h5"-->
        <!--          >{{$t('login_page.Contact_Us')}}</a-->
        <!--          >-->
        <!--        </div>-->
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <div
          class="login-aside d-flex flex-column flex-row-auto"
      >
        <div class="d-flex flex-column-auto flex-column p-15">
          <a href="https://sajlha.com" class="mb-10 d-flex justify-content-end">
            <img v-if="main_logo" :src="main_logo" class="max-h-55px d-md-block d-none" alt="sajlha"/>
          </a>
          <div class="text-center padding-top-90px mb-10">
            <a href="https://wa.me/0535222795"><img src="media/logos/SupportQR.png" class="max-width-200" alt="sajlha"/></a>
          </div>
          <h1 class="font-weight-800 text-center f-s-3rem color-1a5a4a">{{ $t('login_page.we_share_your_journey') }}</h1>
          <p class="text-center color-1a5a4a f-s-22px padding-right-84px">{{ $t('login_page.and_we_help_you_with_all_your_procedures') }}</p>
        </div>
        <div class="d-flex justify-content-end logo-icon-container">
          <img :src="backgroundImage" class="max-width-456 logo-icon-ss" alt="sajlha"/>
        </div>
      </div>
      <!--begin::Aside-->

    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import {getToken} from "firebase/messaging";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import {mapGetters, mapState} from "vuex";
import {LOGIN, LOGOUT, REGISTER} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import I18nService from "@/core/services/i18n.service";
import ApiService from "@/core/services/api.service";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
        token: null,
      },
      validation: null,
      error_msg: null,
      main_logo: null,
    };
  },
  watch: {
    "form.email": function () {
      if (this.validation && this.validation.email)
        this.validation.email = null;
    },
    "form.password": function () {
      if (this.validation && this.validation.password)
        this.validation.password = null;
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
          process.env.BASE_URL + "media/logos/Sajlha-Icon.png"
      );
    }
  },
  mounted() {
    let that = this;
    this.getCompanyInfo();
    /**
     * get new token from firebase
     */
    getToken(window.firebase_messaging).then((currentToken) => {
      if (currentToken) {
        that.form.token = currentToken;
        console.log(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
    I18nService.getActiveLanguage();
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t('login_page.Username_is_required')
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t('login_page.Password_is_required')
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: this.$t('login_page.Full_name_is_required')
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: this.$t('login_page.Email_is_required')
            },
            emailAddress: {
              message: this.$t('login_page.The_value_is_not_a_valid_email_address')
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t('login_page.Password_is_required')
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: this.$t('login_page.Confirm_password_is_required')
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: this.$t('login_page.The_password_and_its_confirm_are_not_the_same')
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: this.$t('login_page.You_should_agree_terms_and_conditions')
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t('login_page.Email_is_required')
            },
            emailAddress: {
              message: this.$t('login_page.The_value_is_not_a_valid_email_address')
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;
      var token = this.form.token;
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
            .dispatch(LOGIN, {email, password, token})
            // go to which page after successfully login
            .then(() => this.$router.push({name: "dashboard"}))
            .catch((error) => {
              if (error.data.errors) {
                this.validation = error.data ? error.data.errors : null;
              } else {
                this.error_msg = error.data ? error.data.message : null;
              }
            });

        submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      // Swal.fire({
      //   title: "",
      //   text: this.$t('login_page.Please_provide_correct_data'),
      //   icon: "error",
      //   confirmButtonClass: "btn btn-secondary",
      //   heightAuto: false
      // });
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
            .dispatch(REGISTER, {
              email: email,
              password: password
            })
            .then(() => this.$router.push({name: "dashboard"}));

        submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );
      }, 2000);
    });

    this.fv1.on("core.form.invalid", () => {
      // Swal.fire({
      //   title: "",
      //   text: this.$t('login_page.Please_provide_correct_data'),
      //   icon: "error",
      //   confirmButtonClass: "btn btn-secondary",
      //   heightAuto: false
      // });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
          KTUtil.getById(form_name),
          "animate__animated animate__backInUp"
      );
    },
    getLinkResetPassword() {
      return "https://sajlha.com/" + I18nService.getCurrentLanguage() + "/password/reset";
    },
    getCompanyInfo() {
      ApiService.get("/base/company_info").then((response) => {
        this.main_logo = response.data.data.logo_url;
      });
    },
  }
};
</script>
